import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import DailyMenu from "../components/dailyMenu/dailyMenu"

const Privacy = () => (
  <Layout>
    <SEO title="Polityka prywatności" />
    <div className="content-page">
      <h1>Polityka prywatności</h1>
      <hr />
      <p style={{ margin: "20px 0" }}>
        <em>Data ostatniej aktualizacji: 04.06.2018 </em>
      </p>
      <div style={{ padding: "10px 10px 20px", fontSize: "1rem" }}>
        Niniejsza Polityka Prywatności określa zasady przechowywania i dostępu
        do informacji na urządzeniach Użytkownika za pomocą plików Cookies na
        stronie kantyna ABB. Administratorem serwisu i danych osobowych jest
        firma crow media z siedzibą w Bielsku-Białej przy ulicy Legionów 81,
        reprezentowana przez Macieja Wronę.
      </div>
      <h3>Definicje</h3>
      <p>
        <strong>1. Administrator </strong>– firma crow media z siedzibą w
        Bielsku-Białej przy ulicy Legionów 81, reprezentowana przez Macieja
        Wronę
      </p>
      <p>
        2. <strong>Cookies</strong> – oznacza dane informatyczne, w
        szczególności niewielkie pliki tekstowe, zapisywane i przechowywane na
        urządzeniach za pośrednictwem których Użytkownik korzysta ze stron
        internetowych Serwisu.
      </p>
      <ul>
        <li>
          przykład użycia plików cookies: Użytkownik wchodzi na stronę na której
          znajduje się infrmacja o używaniu plików cookies. Użytkownik
          potwierdza zgodę na użytkowanie plików cookies. W przeglądarce
          użytkowniaka za pomocą cookies zapisywana jest informacja o zgodzie
          użytkowanika i przy następnej wizycie komunikat nie zostanie ponownie
          wyświetlony.
        </li>
      </ul>
      <p>
        3. <strong>Serwis</strong> – oznacza stronę internetową lub aplikację,
        pod którą Administrator prowadzi serwis internetowy, działającą(y) w
        domenie abb.wronacatering.pl.
      </p>
      <p>
        4. <strong>Urządzenie</strong> – oznacza elektroniczne urządzenie za
        pośrednictwem, którego Użytkownik uzyskuje dostęp do Serwisu.
      </p>
      <p>
        5. <strong>Użytkownik</strong> – każda osoba korzystająca z Serwisu.
      </p>
      <p> </p>
      <h3>I. Dane osobowe</h3>
      <p>
        1. Użytkownik może przekazywać swoje dane osobowe Administratorowi
        korzystając z formularza kontaktowego oraz formularza zapisu na
        newsletter. Dane te przekazuje zawsze dobrowolnie, są one jednak
        konieczne, aby zrealizować konkretny cel:
      </p>
      <ul>
        <li>
          formularz kontaktowy ma na celu umożliwić kontakt z Administratorem
          jeśli Użytkownik tego potrzebuje,
        </li>
        <li>
          formularz zapisu do newslettera ma na celu umożliwić przesyłanie
          treści związanych z serwisem np. menu dnia.
        </li>
        <li></li>
      </ul>
      <p>
        2. Administratorem danych jest firma crow media z siedzibą w
        Bielsku-Białej przy ulicy Legionów 81, reprezentowana przez Macieja
        Wrona
      </p>
      <p>
        3. Administrator gwarantuje poufność wszelkich przekazanych mu danych
        osobowych. Na wszystkich stronach w Serwisie zainstalowany jest
        certyfikat SSL, który umożliwia bezpieczne przesyłanie danych. Dane
        osobowe są gromadzone z należytą starannością i odpowiednio chronione
        przed dostępem do nich przez osoby do tego nieupoważnione. Dostęp do
        danych jest chroniony unikalnym hasłem i loginem Administratora. Nikt
        inny nie posiada konta w Serwisie i nie ma dostępu do tych danych.
      </p>
      <p>4. Użytkownik ma prawo do:</p>
      <ul>
        <li>
          żądania dostępu do swoich danych, ich sprostowania, usunięcia lub
          ograniczenia przetwarzania,
        </li>
        <li>wniesienia sprzeciwu wobec przetwarzania,</li>
        <li>przenoszenia danych,</li>
        <li>
          cofnięcia zgody na przetwarzanie danych osobowych w określonym celu,
          jeżeli Użytkownik uprzednio taką zgodę wyraził,
        </li>
        <li>
          wniesienia skargi do organu nadzorczego w związku z przetwarzaniem
          danych osobowych przez Administratora.
        </li>
      </ul>
      <p>
        Prawa te może realizować kontaktując się z Administratorem pod adresem
        e-mail: biuro@wronacatering.com.
      </p>
      <h3>II. Powierzenie przetwarzania danych</h3>
      <p>
        Dane osobowe są gromadzone zgodnie z zasadą
        <em>
          privacy by default (minimalizacji danych potrzebnych do wypełnienia
          celu przetwarzania)
        </em>
        i przekazywane jedynie do:
      </p>
      <ul>
        <li>
          zenbox sp. z o.o., z siedzibą przy ul. Dąbrowskiego 7, 42-200
          Częstochowa, NIP: PL949-219-10-21, REGON: 242888558, KRS: 0000414281 –
          w celu przechowywania ich na serwerze. Podmiot ten gwarantuje poufność
          danych zapisywanych w jego bazach. Sposób ich zabezpieczenia został
          opisany w pliku:
          <a href="http://zenbox.pl/files/giodo/zenbox_giodo_zabezpieczenie_techniczne.doc">
            http://zenbox.pl/files/giodo/zenbox_giodo_zabezpieczenie_techniczne.doc
          </a>
        </li>
        <li>
          Google (wykorzystane narzędzie to Google Analytics)- w celu analizy
          statystyk strony. Podmiot ten gwarantuje poufność danych zapisywanych
          w jego bazach. Dane o użytkownikach i zdarzeniach są anonimowe i
          przechowywane prze 26 miesięcy, a następnie usuwane z serwerów firmy
          Google. Czas ten jest liczony od ostatniej interakcji użytkownika z
          Serwisem, przy każdej interakcji jest naliczany od początku. Możesz
          przeczytać o tym, jak Google zabezpiecza te dane:
          <a href="https://privacy.google.com/businesses/processorterms/">
            https://privacy.google.com/businesses/processorterms/
          </a>
        </li>
      </ul>
      <p>
        Dane nie są sprzedawane i udostępniane żadnym innym podmiotom trzecim.
        Jedynym wyjątkiem są wymienione powyżej podmioty, które świadczą usługi
        umożliwiające prawidłowe działanie Serwisu.
      </p>
      <h3>III. Cele w jakich wykorzystywane są Cookies</h3>
      <h3>1. Administrator wykorzystuje Cookies w następujących celach:</h3>
      <p>
        <strong>
          a. dopasowania do potrzeb Użytkownika oraz ułatwiania korzystania z
          Serwisu, w tym:
        </strong>
      </p>
      <ul>
        <li>
          dostosowania zawartości stron internetowych Serwisu do preferencji
          Użytkownika,
        </li>
        <li>
          zbierania i analizowania danych o Urządzeniu Użytkownika w celu
          poprawiania działania Serwisu i ułatwiania korzystania z niego,
        </li>
        <li>
          rozpoznania urządzenia Użytkownika Serwisu i odpowiednio wyświetlenia
          strony internetowej, dostosowanej do jego Urządzenia (inny wygląd dla
          ekranu monitora, inny dla urządzenia mobilnego),
        </li>
      </ul>
      <p>
        <strong>b. przesyłania newslettera i informacji handlowych</strong>
      </p>
      <ul>
        <li>
          przesyłania informacji poprzez newsletter, jeśli Użytkownik wyraził
          jasną zgodę na takie działania oraz zrobił to dobrowolnie. W każdej
          chwili ma możliwość wycofania swojej zgody na te działania kontaktując
          się z Administratorem wykorzystując do kontaktu adres
          e-mail:biuro@wronacatering.com;
        </li>
        <li>
          przesyłania informacji handlowych poprzez newsletter, jeśli Użytkownik
          wyraził jasną zgodę na takie działania oraz zrobił to dobrowolnie. W
          każdej chwili ma możliwość wycofania swojej zgody na te działania
          kontaktując się z Administratorem wykorzystując do kontaktu adres
          e-mail: biuro@wronacatering.com
        </li>
      </ul>
      <p>
        <strong>c. zapewnienia bezpieczeństwa i niezawodności serwisu</strong>
      </p>
      <p>
        <strong>
          e. zbierania danych statycznych za pośrednictwem narzędzi
          analitycznych:
        </strong>
      </p>
      <ul>
        <li>
          Google Analytics [administrator cookies: Google Inc z siedzibą w USA]
        </li>
      </ul>
      <h3>
        IV. Możliwości określenia warunków przechowywania lub uzyskiwania
        dostępu przez Cookies
      </h3>
      <p>
        1. Użytkownik może samodzielnie i w każdym czasie zmienić ustawienia
        dotyczące plików Cookies, określając warunki ich przechowywania i
        uzyskiwania dostępu przez pliki Cookies do Urządzenia Użytkownika.
        Zmiany ustawień, o których mowa w zdaniu poprzednim, Użytkownik może
        dokonać za pomocą ustawień przeglądarki internetowej lub za pomocą
        konfiguracji usługi. Ustawienia te mogą zostać zmienione w szczególności
        w taki sposób, aby blokować automatyczną obsługę plików cookies w
        ustawieniach przeglądarki internetowej bądź informować o ich
        każdorazowym zamieszczeniu Cookies na urządzeniu Użytkownika.
        Szczegółowe informacje o możliwości i sposobach obsługi plików cookies
        dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).
      </p>
      <p>
        2. Użytkownik może w każdej chwili usunąć pliki Cookies korzystając z
        dostępnych funkcji w przeglądarce internetowej, której używa.
      </p>
      <p>
        3. Ograniczenie stosowania plików Cookies, może wpłynąć na niektóre
        funkcjonalności dostępne na stronie internetowej Serwisu.
      </p>
    </div>
  </Layout>
)

export default Privacy
