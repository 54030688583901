import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Menu dnia" />

      <div style={{ padding: "20px" }} className="dailyMenu">
        <h1>Kontakt</h1>
        <p>
          {/* TODO add phone link see phoneOrder component Telefon <br /> */}
          +48 517 843 423 <br />
          +48 508 193 801
        </p>

        <p style={{ marginTop: "15px" }}>
          Email <br />
          biuro@wronacatering.pl
        </p>
        <p style={{ marginTop: "15px" }}>
          Adres <br />
          ul. Rudawka 96, 43-300 Bielsko-Biała
        </p>
      </div>
    </Layout>
  )
}

export default ContactPage
