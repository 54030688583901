module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"wrona-abb-client","short_name":"abb","start_url":"/","background_color":"#ECECEB","theme_color":"#ECECEB","display":"minimal-ui","icon":"src/images/logo-fav.jpg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"341e8650ad6c8aaaaf7448b94f3b5b6a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-STC321CYVE"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
