/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import DailyMenuContext from "../../context/DailyMenuContext"

import Header from "../header/header"
import "../../styles/styles.scss"
import Footer from "../footer/footer"
import TopNav from "../topNav/TopNav"
import Main from "../main/main"
import CookiesNotice from "../cookies/CookiesNotice"
import PromotionPopup from "../PromotionPopup/PromotionPopup"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <DailyMenuContext.Consumer>
      {menu => (
        <div className="main-wrapper">
          <Header siteTitle={data.site.siteMetadata.title} />
          <Main>{children}</Main>
          <Footer />
          <CookiesNotice />
          {/* <PromotionPopup /> */}
        </div>
      )}
    </DailyMenuContext.Consumer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
