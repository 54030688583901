import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { IconCookie } from "../svgIcons/svgIcons"
import "./cookieNoticeStyles.scss"

const cookieName = "abb-cookies#fdssfsfds"

const CookiesNotice = () => {
  const [showCookie, toggleCookie] = useState(false)

  const isBrowser = () => typeof window !== "undefined"

  const handleAcceptCookie = () => {
    if (!isBrowser) return // skip on gatsby build when window === undefined

    window.localStorage.setItem(cookieName, true)
    toggleCookie(false)
  }

  const handleCookie = () => {
    if (!isBrowser) return // skip on gatsby build when window === undefined

    const isCookieConfirmed = window.localStorage.getItem(cookieName)

    if (!isCookieConfirmed) {
      toggleCookie(true)
    }
  }

  useEffect(() => {
    handleCookie()
  }, [])

  if (!showCookie) return null

  return (
    <div className="cookies-notice">
      <p>
        <IconCookie />
        Używamy cookies do celów analitycznych i funkcjonalnych. Korzystając z
        tej strony zgadzasz się na ich przetwarzanie. Dowiedz się więcej -
        <Link to="/privacy/">polityka prywatności</Link>.
      </p>
      <div style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}>
        <button onClick={handleAcceptCookie}>OK</button>
      </div>
    </div>
  )
}

export default CookiesNotice

// TODO implement <a href="javascript:gaOptout();">Deactivate Google Analytics</a> = ZGADZAM SIĘ / NIE ZGADZAM zamiast OK
