import { Link } from "gatsby"
import _throttle from "lodash.throttle"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import WronaLogo from "../../images/wrona-logo.png"
import TopNav from "../topNav/TopNav"
import "./headerStyles.scss"

const TopLogo = ({ size }) => {
  return (
    <div className="header-logo">
      <Link to="/">
        <img src={WronaLogo} />
      </Link>
    </div>
  )
}

const PhoneOrder = () => {
  return (
    <div
      itemScope
      itemType="http://schema.org/LocalBusiness"
      className="phoneOrders"
    >
      <p itemProp="name">
        zamówienia{" "}
        <span itemProp="telephone">
          <a href="tel:+48515577088">515577088</a>
        </span>
      </p>
    </div>
  )
}

const Header = ({ siteTitle }) => {
  const [headerType, setHeaderType] = useState("normal")
  const headerRef = useRef(null)

  const trackScroll = () => {
    if (typeof window === "undefined") return

    window.addEventListener(
      "scroll",
      _throttle(function () {
        if (headerRef.current) {
          const windowPos = this.window.scrollY
          const headerPos = headerRef.current.offsetHeight
          if (headerPos - windowPos < 0) {
            setHeaderType("small")
          } else {
            setHeaderType("normal")
          }
        }
      }, 500)
    )
  }

  useEffect(() => {
    trackScroll()
  }, [])

  return (
    <header ref={headerRef} className={headerType}>
      <div className="header-inner">
        <TopLogo size={headerType} />
        <TopNav size={headerType} />
        <div className="header-right">
          <PhoneOrder />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  logo: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  logo: WronaLogo,
}

export default Header
