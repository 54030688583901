import React from "react"
import "./InfoBox.scss"

const InfoBox = () => {
  return (
    <div className="InfoBox">
      <div>
        <p className="InfoBox__paragraph">
          Obiady mięsne <b>3,20zł / 100g </b>{" "}
        </p>
        <p className="InfoBox__paragraph">
          Dania jarskie <b>2,80zł / 100g</b>{" "}
        </p>
        <p className="InfoBox__paragraph">
          Mięso bez dodatków <b>3,80zł / 100g</b>{" "}
        </p>
      </div>
    </div>
  )
}

export default InfoBox
