import React, { useRef, useEffect, useState } from "react"
import "./fixedMenuStyles.scss"
import InfoBox from "../shared/InfoBox"
import { KANAPKI, ZESTAWY, OBIADY, SALADS, PIZZA, ZAPIEKANKI } from "./menuData"

const MenuCategoryContainer = React.forwardRef(({ name, children }, ref) => {
  return (
    <div ref={ref} className={`menuCategoty-container`}>
      <h2 className="menuCategory-title">{name}</h2>
      {children}
    </div>
  )
})
const MenuSubCategoryContainer = ({ name, children }) => {
  return (
    <div className="menuSubCategory-container">
      <h3 className="menuSubCategory-title">{name}</h3>
      <hr />
      {children}
    </div>
  )
}

const MenuItem = ({ name, price, sides }) => {
  const getPrice = (price = 0) => {
    if (Array.isArray(price)) {
      return `${price[0].toFixed(2)} / ${price[1].toFixed(2)}`
    }
    return price.toFixed(2)
  }
  return (
    <div className="menuItem-container">
      <p className="menuItem-name">{name}</p>
      <p className="menuItem-price">{getPrice(price)}</p>
      <p className="menuItem-sides">{sides}</p>
    </div>
  )
}

const MenuNavItem = ({ name, scrollTarget, isActive, clickHandler }) => {
  const scrollTo = ref => {
    window.scroll({
      top: ref.current.offsetTop - 120,
      left: 0,
      behavior: "smooth",
    })
  }
  return (
    <button
      className={`fixedMenu-nav-item ${isActive ? "active" : ""}`}
      onClick={() => {
        scrollTo(scrollTarget)
        clickHandler()
      }}
    >
      {name}
    </button>
  )
}

const MenuItemsList = ({ items }) => {
  return items.map((item, i) => <MenuItem key={i} {...item} />)
}

const CategoryItem = React.forwardRef(({ name, subCategories }, ref) => {
  return (
    <>
      <MenuCategoryContainer ref={ref} name={name}>
        {subCategories.map((sub, i) => {
          return (
            <MenuSubCategoryContainer key={i} name={sub.name}>
              <MenuItemsList items={sub.items} />
            </MenuSubCategoryContainer>
          )
        })}
      </MenuCategoryContainer>
    </>
  )
})

const FixedMenu = () => {
  const [visibleCategory, setVisibleCategory] = useState(null)

  const breakfast = useRef(null)
  const lunch = useRef(null)
  const salads = useRef(null)
  const pizza = useRef(null)
  const canaps = useRef()

  const menuItems = [
    {
      name: "obiady",
      scrollTarget: lunch,
    },
    {
      name: "śniadania",
      scrollTarget: breakfast,
    },
    {
      name: "kanapki",
      scrollTarget: canaps,
    },
    {
      name: "sałatki",
      scrollTarget: salads,
    },
    {
      name: "pizza i zapiekanki",
      scrollTarget: pizza,
    },
  ]

  useEffect(() => {}, [])

  return (
    <div className="dailyMenu">
      {/*  */}
      <div className="dailyMenu-header menu">
        <div className="fixedMenu-nav">
          {menuItems.map((item, i) => {
            return (
              <MenuNavItem
                key={i}
                isActive={item.name === visibleCategory}
                {...item}
                clickHandler={() => setVisibleCategory(item.name)}
              />
            )
          })}
        </div>
      </div>
      {/*  */}
      <div
        style={{ padding: "1rem 0.5rem", maxWidth: "100%" }}
        className="dailyMenu-content"
      >
        {/* <MenuCategoryContainer ref={lunch} name="Obiady">
          <MenuSubCategoryContainer name="Zestawy obiadowe">
            <MenuItemsList items={OBIADY} />
          </MenuSubCategoryContainer>
        </MenuCategoryContainer> */}
        {/* TODO:
          - refactor rest of MenuCategoryContainers
        */}
        <CategoryItem
          name="Obiady"
          ref={lunch}
          subCategories={[{ name: "Zestawy obiadowe", items: OBIADY }]}
        />
        <MenuCategoryContainer ref={breakfast} name="Śniadania">
          <MenuSubCategoryContainer name="Zestawy śniadaniowe">
            <MenuItemsList items={ZESTAWY} />
          </MenuSubCategoryContainer>
        </MenuCategoryContainer>
        <MenuCategoryContainer ref={canaps} name="Kanapki">
          <MenuSubCategoryContainer name="Pieczywo i kanapki">
            <MenuItemsList items={KANAPKI} />
          </MenuSubCategoryContainer>
        </MenuCategoryContainer>
        <CategoryItem
          name="Pizza i zapiekanki"
          ref={pizza}
          subCategories={[
            { name: "Pizza", items: PIZZA },
            { name: "Zapiekanki", items: ZAPIEKANKI },
          ]}
        />
        {/* <MenuCategoryContainer ref={pizza} name="Pizza i zapiekanki">
          <MenuSubCategoryContainer name="Pizza 30 cm">
            <MenuItemsList items={PIZZA} />
          </MenuSubCategoryContainer>
          <MenuSubCategoryContainer name="Zapiekanki">
            <MenuItemsList items={ZAPIEKANKI} />
          </MenuSubCategoryContainer>
        </MenuCategoryContainer> */}
        <MenuCategoryContainer ref={salads} name="Sałatki">
          <MenuSubCategoryContainer name="Sałatki">
            <MenuItemsList items={SALADS} />
          </MenuSubCategoryContainer>
        </MenuCategoryContainer>
      </div>
    </div>
  )
}

export default FixedMenu
