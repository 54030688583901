import React from "react"
import LoaderPlate from "../shared/LoaderPlate"

const DaillyMenuLoading = () => {
  return (
    <div className="dailyMenu">
      <div className="dailyMenu-header">
        <div
          style={{ maxWidth: "100%", margin: "auto" }}
          className="dailyMenu-content"
        >
          <LoaderPlate />
        </div>
      </div>
    </div>
  )
}

export default DaillyMenuLoading
