import React from "react"

const defaultState = null

const DailyMenuContext = React.createContext(defaultState)

class DailyMenuProvider extends React.Component {
  state = {
    menu: null,
  }

  setMenu = menu => {
    this.setState({
      menu: {
        ...menu,
        danie: menu.danie.filter(item => item.nazwa),
      },
    })
  }

  render() {
    const { children } = this.props
    const { menu } = this.state

    return (
      <DailyMenuContext.Provider
        value={{
          menu: menu,
          setMenu: this.setMenu,
        }}
      >
        {children}
      </DailyMenuContext.Provider>
    )
  }
}

export default DailyMenuContext
export { DailyMenuProvider }
