export const KANAPKI = [
  {
    name: "Drożdzówka",
    price: 2.5,
    sides: "",
  },
  {
    name: "Kajzerka",
    price: 3.5,
    sides: "",
  },
  {
    name: "Wrocławska",
    price: 4.5,
    sides: "",
  },
  {
    name: "Ziarnista",
    price: 4.5,
    sides: "",
  },
  {
    name: "Ciabata",
    price: 4.8,
    sides: "",
  },
  {
    name: "Z kotletem schabowym",
    price: 9.0,
    sides: "",
  },
  {
    name: "Z kotletem schabowym",
    price: 9.0,
    sides: "",
  },
  {
    name: "Z kotletem schabowym",
    price: 9.0,
    sides: "",
  },
  {
    name: "Z grillowanym kurczakiem",
    price: 9.0,
    sides: "",
  },
  {
    name: "Z kotletem mielonym",
    price: 9.0,
    sides: "",
  },
  {
    name: "Z wege burgerem",
    price: 9.0,
    sides: "",
  },
  {
    name: "Z kotletem schabowym",
    price: 9.0,
    sides: "",
  },
]

export const ZESTAWY = [
  {
    name: "Jajecznica na maśle (3 jajka)",
    price: 7.5,
    sides: "pieczywo",
  },
  {
    name: "Jajecznica na szynce (3 jajka)",
    price: 8.5,
    sides: "pieczywo, cebula, ketchup / musztarda",
  },
  {
    name: "Kiełbasa pieczona",
    price: 7.5,
    sides: "pieczywo, cebula, ketchup / musztarda",
  },
  {
    name: "Kiełbasa curry",
    price: 12,
    sides: "frytki",
  },
  {
    name: "Wątróbka smażona",
    price: 8,
    sides: "pieczywo, cebula",
  },
  {
    name: "Kapsalon",
    price: 12,
    sides: "gyros zapiekany z serem i frytkami",
  },
  {
    name: "Krokiet z pieczarkami",
    price: 3.8,
    sides: "",
  },
  {
    name: "Krokiet z mięsem",
    price: 3.8,
    sides: "",
  },
]

export const PIZZA = [
  {
    name: "Margherita",
    price: 14,
    sides: "",
  },
  {
    name: "Z szynką",
    price: 16,
    sides: "",
  },
  {
    name: "Z gyrosem",
    price: 16,
    sides: "",
  },
  {
    name: "4 sery",
    price: 17,
    sides: "",
  },
]

export const ZAPIEKANKI = [
  {
    name: "Z pieczarkami",
    price: 5.5,
    sides: "ketchup / sos duński",
  },
  {
    name: "Z szynką",
    price: 6.5,
    sides: "ketchup / sos duński",
  },
]

export const SALADS = [
  {
    name: "Grecka",
    price: [9.5, 13.5],
    sides: "sos",
  },
  {
    name: "Capresse",
    price: [9.5, 13.5],
    sides: "sos",
  },
  {
    name: "Z grillowanym kurczakiem",
    price: [10, 14],
    sides: "sos",
  },
]

export const OBIADY = [
  {
    name: "Smażony ser",
    price: 16,
    sides: "frytki, zestaw surówek, sos tatarski",
  },
  {
    name: "Kotlet schabowy",
    price: 14,
    sides: "puree ziemniaczane, kapusta zasmażana",
  },
  {
    name: "Kotlet drobiowy",
    price: 14,
    sides: "ziemniaki, zestaw surówek",
  },
  {
    name: "Grillowana pierś z kurczaka",
    price: 16,
    sides: "frytki, zestaw surówek",
  },
  {
    name: "Kotlet mielony",
    price: 12,
    sides: "puree ziemniaczane, kapusta zasmażana",
  },
  {
    name: "Gyros drobiowy",
    price: 16,
    sides: "frytki, zestaw surówek, sos czosnkowy",
  },
  {
    name: "Pierogi z mięsem",
    price: 12,
    sides: "",
  },
  {
    name: "Pierogi ruskie",
    price: 12,
    sides: "",
  },
  {
    name: "Naleśniki z serem na słodko",
    price: 4.5,
    sides: "",
  },
]
