import React from "react"
import { Link } from "gatsby"
import "./footerStyles.scss"

const Footer = () => {
  return (
    <footer>
      <h6 className="address">
        RESTAURACJA ABB <br /> BIELSKO-BIAŁA <br />
        ul. Rudawka 96
      </h6>

      <p className="legal">
        © {new Date().getFullYear()}{" "}
        <a href="http://www.wronacatering.pl">
          Wrona Catering &amp; Food Service
        </a>
      </p>

      {/* TODO: add OutboundLink to wronacatering.pl link. see https://www.gatsbyjs.org/packages/gatsby-plugin-google-analytics/  */}

      <p className="legal">
        <Link to="privacy">Polityka prywatności</Link>
      </p>
    </footer>
  )
}

export default Footer
