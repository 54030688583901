import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import DailyMenu from "../components/dailyMenu/dailyMenu"
import FixedMenu from "../components/menu/FixedMenu"

const MenuPage = () => (
  <Layout>
    <SEO title="Menu" />
    <FixedMenu />
  </Layout>
)

export default MenuPage
