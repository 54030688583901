import React, { useState, useEffect, useContext } from "react"
import DailyMenuContext from "../../context/DailyMenuContext"
import InfoBox from "../shared/InfoBox"
import "./dailyMenuStyles.scss"
import { IconArrowNext, IconPlate } from "../svgIcons/svgIcons"
import { Link } from "gatsby"
import axios from "axios"
import LoaderPlate from "../shared/LoaderPlate"
import DaillyMenuError from "./DaillyMenuError"
import DaillyMenuLoading from "./DaillyMenuLoading"

// menu schema https://wrona-cms.herokuapp.com/menu

const DailyMenuItem = ({ name, price, sides, id }) => {
  if (Array.isArray(price) && price.length) {
    price = price.toFixed(2)
  }

  return (
    <li>
      <div className="dailyMenu-meal">
        <span className="dailyMenu-meal_name">{name}</span>
        <div className="dailyMenu-meal_spaner"></div>
        <span className="dailyMenu-meal_price"> {price}</span>
      </div>
      <span className="dailyMenu-meal_sides">{sides}</span>
    </li>
  )
}

const DailyMenu = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [menu, setMenu] = useState(null)

  const menuCtx = useContext(DailyMenuContext)

  const getMenuFromApi = cb => {
    axios
      .get("https://ge.wronacatering.pl/wp-json/wp/v2/menu-abb")
      .then(response => {
        setMenu(response.data[0].content.rendered)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        setError(true)
      })
      .then(data => {
        cb && cb(data)
      })
  }

  useEffect(() => {
    if (!menu) {
      getMenuFromApi()
    } else {
      setLoading(false)
    }
  }, [])

  if (error) return <DaillyMenuError />

  if (loading) return <DaillyMenuLoading />

  return (
    <div className="dailyMenu">
      <div className="dailyMenu-header">
        <div>
          <h1>Menu dnia</h1>
          {/* <p>{menuCtx.menu.data_txt}</p> */}
          {/* //TODO: add date-fns and parse get content from menu.data */}
        </div>
        <div>
          <Link className="dailyMenu-header-link" to="/menu/">
            Stałe menu
            <IconArrowNext />
          </Link>
        </div>
      </div>

      <div className="dailyMenu-content">
        <InfoBox />
        <div className="dailyMenu-list">
          {/* //TODO: danie is [] api to adjust   */}
          <div
            style={{ marginTop: "20px", padding: "5px" }}
            dangerouslySetInnerHTML={{ __html: menu }}
          />
        </div>
      </div>
    </div>
  )
}

export default DailyMenu
