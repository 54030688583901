import React, { useEffect, useState } from "react"
import { IconPlate } from "../svgIcons/svgIcons"
import "./plateLoaderStyles.scss"

const LoaderPlate = () => {
  const [transparent, setTransparent] = useState(true)

  const deferLoader = time => {
    setTimeout(() => {
      setTransparent(false)
    }, time)
  }

  useEffect(() => {
    deferLoader(300)
  }, [])
  return <IconPlate className={`plateLoader ${transparent ? "hide" : ""}`} />
}

export default LoaderPlate
