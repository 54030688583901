import React from "react"

const defaultErrorMsg = `Coś poszło nie tak. Prosiły spróbować później.`

const DaillyMenuError = ({ errorMsg = defaultErrorMsg }) => {
  return (
    <div className="dailyMenu">
      <div className="dailyMenu-header">
        <div className="dailyMenu-content">
          <p>{errorMsg}</p>
        </div>
      </div>
    </div>
  )
}

export default DaillyMenuError
