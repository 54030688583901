import React from "react"

export const IconArrowNext = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.002 512.002">
    <path d="M388.425 241.951L151.609 5.79c-7.759-7.733-20.321-7.72-28.067.04-7.74 7.759-7.72 20.328.04 28.067l222.72 222.105-222.728 222.104c-7.759 7.74-7.779 20.301-.04 28.061a19.8 19.8 0 0014.057 5.835 19.79 19.79 0 0014.017-5.795l236.817-236.155c3.737-3.718 5.834-8.778 5.834-14.05s-2.103-10.326-5.834-14.051z" />
  </svg>
)

export const IconPlate = ({ className, style }) => {
  return (
    <svg className={className} style={style} viewBox="0 0 47 47" fill="#ccc">
      <path d="M29.379 38.382a2.744 2.744 0 0 1-2.02-.89 2.483 2.483 0 0 1-.626-1.929l.563-12.59a4.036 4.036 0 0 1-2.088-2.827 9.342 9.342 0 0 1 .26-4.3l.5-1.66a3.595 3.595 0 0 1 6.809 0l.5 1.655a9.342 9.342 0 0 1 .265 4.305 4.03 4.03 0 0 1-2.084 2.826l.566 12.644a2.451 2.451 0 0 1-.626 1.876c-.055.06-.113.118-.173.173a2.731 2.731 0 0 1-1.846.717zm0-25.065a2.04 2.04 0 0 0-1.911 1.329l-.5 1.654a7.762 7.762 0 0 0-.215 3.577 2.488 2.488 0 0 0 1.624 1.869.782.782 0 0 1 .507.769l-.59 13.172a.938.938 0 0 0 .23.761 1.211 1.211 0 0 0 1.7 0 .91.91 0 0 0 .235-.71l-.591-13.222a.782.782 0 0 1 .506-.77 2.491 2.491 0 0 0 1.625-1.869 7.775 7.775 0 0 0-.216-3.577l-.503-1.654a2.031 2.031 0 0 0-1.9-1.327z"></path>
      <path d="M21.15 11.75a.783.783 0 0 0-.783.783v6.726l-2.12 2.121a.783.783 0 0 0-.23.593l.685 13.708a1.067 1.067 0 0 1-.3.8 1.1 1.1 0 0 1-1.56.007l-.007-.007a1.067 1.067 0 0 1-.3-.8l.69-13.708a.784.784 0 0 0-.229-.593l-2.112-2.121v-6.726a.7835.7835 0 1 0-1.567 0v7.05a.783.783 0 0 0 .23.554l2.1 2.1-.665 13.368a2.64615 2.64615 0 0 0 5.286.258q.006-.129 0-.257L19.6 22.242l2.1-2.1a.783.783 0 0 0 .23-.554v-7.055a.783.783 0 0 0-.78-.783z"></path>
      <path d="M16.45 11.75a.783.783 0 0 0-.783.783V18.8a.7835.7835 0 0 0 1.567 0v-6.267a.783.783 0 0 0-.784-.783z"></path>
      <path d="M18.8 11.75a.783.783 0 0 0-.783.783V18.8a.7835.7835 0 0 0 1.567 0v-6.267a.783.783 0 0 0-.784-.783z"></path>
      <path d="M23.5 47a23.506 23.506 0 0 1-9.147-45.153 23.506 23.506 0 0 1 18.294 43.306A23.353 23.353 0 0 1 23.5 47zm0-45.433a21.939 21.939 0 0 0-8.537 42.142A21.939 21.939 0 0 0 32.038 3.291 21.8 21.8 0 0 0 23.5 1.567z"></path>
      <path d="M26.043 38.204a.783.783 0 0 0-.856-.707h-.03a14.39 14.39 0 0 1-3.32 0 .78667.78667 0 0 0-.18 1.563 15.867 15.867 0 0 0 3.682 0 .783.783 0 0 0 .704-.856z"></path>
      <path d="M35.485 13.406a.783.783 0 0 0-1.1-.1.783.783 0 0 0-.094 1.1 14.1 14.1 0 0 1-.838 19.081.78348.78348 0 0 0 1.106 1.11 15.667 15.667 0 0 0 .926-21.191z"></path>
      <path d="M18.269 10.354z"></path>
      <path d="M27.194 8.271a15.805 15.805 0 0 0-9.22.565.783.783 0 1 0 .549 1.466 14.231 14.231 0 0 1 8.3-.509.78328.78328 0 1 0 .371-1.522z"></path>
      <path d="M13.511 33.452a14.1 14.1 0 0 1-1.416-18.246.7831.7831 0 1 0-1.252-.941l-.011.018a15.667 15.667 0 0 0 1.57 20.275.785.785 0 0 0 1.109-1.106z"></path>
    </svg>
  )
}

export const IconCookie = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.767 60.403">
    <path d="M7.365 29.831l-1.447.981a17.859 17.859 0 0 0 7.116 6.216l.75-1.591v-.008h-.008a16.089 16.089 0 0 1-6.411-5.598z"></path>
    <path d="M25.477 3.626a17.474 17.474 0 0 0-1.794-.415l-.3 1.741a15.728 15.728 0 0 1 1.615.374z"></path>
    <path d="M35.122 14.057l1.585-.746a17.955 17.955 0 0 0-10.063-9.3l-.595 1.662a16.186 16.186 0 0 1 9.073 8.384z"></path>
    <path d="M20.634 4.712a15.983 15.983 0 0 1 1.655.086l.18-1.758a17.715 17.715 0 0 0-1.835-.1z"></path>
    <path d="M24.258 29.828l1.467.962a16.313 16.313 0 0 1 .973-1.357l-1.361-1.109a18.075 18.075 0 0 0-1.079 1.504z"></path>
    <path d="M28.402 25.307l1.061 1.4a15.7 15.7 0 0 1 9.671-3.3V21.64a17.423 17.423 0 0 0-10.732 3.667z"></path>
    <path d="M26.137 27.388l1.283 1.2a16.32 16.32 0 0 1 1.188-1.169l-1.154-1.328a18.112 18.112 0 0 0-1.317 1.297z"></path>
    <path d="M44.611 56.581a17.6 17.6 0 0 0 1.716-.667l-.712-1.614a15.879 15.879 0 0 1-1.546.6z"></path>
    <path d="M48.919 52.293l1.074 1.395a17.948 17.948 0 0 0 1.391-1.215l-1.21-1.276a16.212 16.212 0 0 1-1.255 1.096z"></path>
    <path d="M46.608 53.81l.821 1.56a17.808 17.808 0 0 0 1.576-.956l-.976-1.466a16.059 16.059 0 0 1-1.421.862z"></path>
    <path d="M53.408 50.149L52 49.101a16.274 16.274 0 0 1-1.051 1.3l1.293 1.189a18.085 18.085 0 0 0 1.166-1.441z"></path>
    <path d="M13.232 15.877a3.555 3.555 0 0 0-5.065 0 3.645 3.645 0 0 0 0 5.118 3.555 3.555 0 0 0 5.065 0 3.645 3.645 0 0 0 0-5.118zM10.7 20.288a1.852 1.852 0 1 1 1.833-1.852 1.85 1.85 0 0 1-1.834 1.852z"></path>
    <path d="M8.307 12.714a6.115 6.115 0 0 0-1.953 1.33 6.23 6.23 0 0 0-1.8 4.392 6.281 6.281 0 0 0 .824 3.108 6.211 6.211 0 0 0 2.166 2.223l.9-1.515a4.473 4.473 0 0 1-.852-6.957 4.359 4.359 0 0 1 3.11-1.3l.006-1.761-.006-.006a6.058 6.058 0 0 0-2.395.486z"></path>
    <path d="M21.979 16.746a3.041 3.041 0 0 0 .883 2.154 2.989 2.989 0 0 0 4.26 0 3.068 3.068 0 0 0 0-4.306 2.989 2.989 0 0 0-4.26 0 3.041 3.041 0 0 0-.883 2.152zm3.012-1.277a1.278 1.278 0 1 1-1.264 1.277 1.276 1.276 0 0 1 1.264-1.277z"></path>
    <path d="M28.488 18.228a3.785 3.785 0 0 1-3.494 2.349h-.009v1.767h.006a5.471 5.471 0 0 0 3.1-.96 5.573 5.573 0 0 0 2.006-2.473v-.006l-1.608-.681z"></path>
    <path d="M31.187 37.943a3.787 3.787 0 0 0 1.216-.2 3.862 3.862 0 0 0 2.394-4.876v-.006a3.812 3.812 0 0 0-3.606-2.617 3.759 3.759 0 0 0-1.216.2 3.855 3.855 0 0 0 1.214 7.5zm-.655-5.822a2.038 2.038 0 0 1 .657-.111 2.061 2.061 0 0 1 1.953 1.419 2.088 2.088 0 0 1-1.3 2.633 2.033 2.033 0 0 1-.657.11 2.059 2.059 0 0 1-1.953-1.419 2.121 2.121 0 0 1-.108-.664 2.077 2.077 0 0 1 1.407-1.968z"></path>
    <path d="M27.129 36.344l-1.534.851a6.336 6.336 0 0 0 7.627 3h.006l-.556-1.669a4.574 4.574 0 0 1-1.479.246 4.614 4.614 0 0 1-4.056-2.425z"></path>
    <path d="M36.629 46.212a3.339 3.339 0 0 0-2.39 1 3.44 3.44 0 0 0 0 4.829 3.353 3.353 0 0 0 4.779 0 3.44 3.44 0 0 0 0-4.829 3.339 3.339 0 0 0-2.389-1zm0 5.063a1.648 1.648 0 1 1 1.63-1.647 1.645 1.645 0 0 1-1.63 1.644z"></path>
    <path d="M32.428 53.871a5.895 5.895 0 0 0 8.4 0 6.024 6.024 0 0 0 1.74-4.245v-.005h-1.749v.006a4.24 4.24 0 0 1-1.228 3 4.164 4.164 0 0 1-5.93 0 4.25 4.25 0 0 1-1.227-3l-1.743-.006-.006.006a6.023 6.023 0 0 0 1.741 4.245z"></path>
    <path d="M48.252 39.264a3.131 3.131 0 0 0-2.236.937 3.219 3.219 0 0 0 0 4.519 3.139 3.139 0 0 0 4.471 0 3.218 3.218 0 0 0 0-4.519 3.13 3.13 0 0 0-2.235-.937zm0 4.624a1.429 1.429 0 1 1 1.413-1.428 1.426 1.426 0 0 1-1.413 1.428z"></path>
    <path d="M39.133 58.636a19.129 19.129 0 0 1-15.4-30.142l-1.425-1.025a20.925 20.925 0 0 0-1.964 3.437 2.736 2.736 0 0 0-.289-.345 2.669 2.669 0 0 0-3.806 0 2.738 2.738 0 0 0 0 3.847 2.663 2.663 0 0 0 2.722.668 21.188 21.188 0 0 0-.475 4.474v.268A19.036 19.036 0 0 1 1.749 20.853c0-.268.005-.541.017-.811l-1.747-.074c-.012.295-.018.593-.018.886a20.793 20.793 0 0 0 18.6 20.75 20.747 20.747 0 0 0 20.534 18.8 20.246 20.246 0 0 0 9.259-2.213l-.786-1.579a18.526 18.526 0 0 1-8.475 2.024zm-20.977-25.2a.954.954 0 1 1 .943-.954.953.953 0 0 1-.943.956z"></path>
    <path d="M59.572 36.676l-.127-.93-.911.184a11.779 11.779 0 0 1-2.329.232 11.639 11.639 0 0 1-4.449-.876l-.375-.154-.359.189a5.038 5.038 0 0 1-.722.311 4.944 4.944 0 0 1-1.6.265 5 5 0 0 1-4.734-3.44 5.079 5.079 0 0 1 .659-4.53l.234-.334-.1-.4a12.131 12.131 0 0 1 .221-6.693l.29-.892-.9-.238a20.408 20.408 0 0 0-3.2-.577A20.74 20.74 0 0 0 20.635 0 20.485 20.485 0 0 0 6.969 5.229a20.9 20.9 0 0 0-6.794 12.9l1.734.229a18.863 18.863 0 0 1 37.49.34h-.265a20.527 20.527 0 0 0-15.7 7.319l1.33 1.148a18.773 18.773 0 0 1 18.29-6.287 13.938 13.938 0 0 0-.074 6.372 6.862 6.862 0 0 0-.663 5.771 6.746 6.746 0 0 0 6.392 4.643 6.671 6.671 0 0 0 2.153-.358c.2-.069.407-.15.6-.239a13.364 13.364 0 0 0 4.741.862 13.538 13.538 0 0 0 1.736-.112c.052.578.078 1.157.078 1.731a19.033 19.033 0 0 1-8.955 16.237l.921 1.5a20.89 20.89 0 0 0 9.782-17.739 21.3 21.3 0 0 0-.193-2.87z"></path>
  </svg>
)
