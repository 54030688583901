import React from "react"
import "./topNavStyles.scss"
import { Link } from "gatsby"
const TopNav = ({ size }) => {
  return (
    <div className={`topNav ${size === "small" ? "small" : ""}`}>
      <Link activeClassName="active" className="topNav-link" to="/">
        karta dania
      </Link>
      <Link activeClassName="active" className="topNav-link" to="/menu/">
        menu
      </Link>
      <Link activeClassName="active" className="topNav-link" to="/contact">
        kontakt
      </Link>
    </div>
  )
}

export default TopNav
